@import "../Common/variables.module.scss";

.outter {
    flex-direction: row;
    gap: $flex-gap;
    align-items: center;
    background: rgb(0, 0, 0);

    &.stateGreen {
        background: linear-gradient(
            110deg,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(46, 84, 54, 0.6) 78%,
            rgba(56, 136, 61, 0.8) 100%
        );
    }
    &.stateRed {
        background: linear-gradient(
            110deg,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(136, 56, 56, 0.6) 78%,
            rgba(136, 56, 56, 0.8) 100%
        );
    }
    &.stateBlue {
        background: linear-gradient(
            110deg,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(56, 112, 138, 0.6) 78%,
            rgba(56, 112, 138, 0.8) 100%
        );
    }
    &.stateYellow {
        background: linear-gradient(
            110deg,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(135, 136, 56, 0.6) 78%,
            rgba(135, 136, 56, 0.8) 100%
        );
    }
}

.inner {
    display: inherit;
    flex-direction: row;
    gap: $flex-gap;
}

.eventContainer {
    display: flex;
    gap: $flex-gap;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}

.intermediateContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $flex-gap;
    justify-content: flex-start;
    width: 95%;
}
