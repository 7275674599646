@import "./variables.module.scss";

.flexMiddle {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.daypicker {
    width: 200px;
}

.errorButton {
    color: white !important;
    background-color: $error-color !important;
}

.successButton {
    color: white !important;
    background-color: $success-color !important;
}

.errorText {
    color: $error-color;
}

.paperHeadline {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: $flex-gap;
}

.pickerDay {
    background-color: $success-color !important;
}

.container {
    background-color: rgba($color: #000000, $alpha: 0.5);
    border-radius: 8px;
    padding: $padding;
    display: flex;
    flex: 1 1 0px;
}

.background {
    padding: $padding;
    min-height: 400vh;
}
