@import "./Components/Common/variables.module.scss";

.headline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: $flex-gap;
}

.loader {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: $flex-gap;
}
